<template>
  <div id="bg">
    <div class="search"></div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="商户ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="商户名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="shop_passageway" label="商户通道" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="disable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.disable == '1' ? '#33C933' : '#F33333' }">{{scope.row.disable == '1' ? '启用' : '禁用'}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="freezeThaw(scope.row,scope.row.disable == 1 ? '禁用' : '启用')">{{scope.row.disable == 1 ? '禁用' : '启用' }}</span>
          <span class="operation" @click="codeList(scope.row)">通道码</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <el-dialog :title="title" v-model="dialog" style="overflow-y: auto" width="492px" top="10vh" :close-on-click-modal="false" >
      <div class="search">
        <el-input style="width: 200px" v-model="code_input" @keyup="searchCode" placeholder="请输入通道码或则标题"></el-input>
        <el-button type="primary" size="medium" @click="showAdd">新增通道</el-button>
      </div>
      <el-table :data="codeDataList" :header-cell-style="{background:'#F7F8FA'}">
<!--        <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>-->
        <el-table-column prop="code" label="通道码" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="label" label="通道标题" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <span class="operation" @click="codeDel(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog :title="title+' - 添加'" v-model="dialogAdd" style="overflow-y: auto" width="492px" top="10vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4">通道码</el-col>
        <el-col :span="20"><el-input v-model="add.code" placeholder="请输入通道码!"></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="4">通道标题</el-col>
        <el-col :span="20"><el-input v-model="add.label" placeholder="请输入通道标题!"></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20"><el-button @click="codeAdd">添加</el-button></el-col>
      </el-row>

    </el-dialog>

  </div>
</template>

<script>
import { getList, freezeThaw } from "@/api/finance/third";
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "financeRecharge",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
      },
      tableData: [], //数据
      title:"",
      dialog:false,
      codeDataListOrigin:[],
      codeDataList:[],
      dialogAdd:false,
      add:{
        code:"",
        label:"",
        shop_id:1,
      },
      row:null,
      code_input:"",
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    searchCode(){
      if (this.code_input == ""){
        this.codeDataList = this.codeDataListOrigin
        return

      }
      var list = []
      for(const row of this.codeDataListOrigin){
        if(row.label.indexOf(this.code_input) >=0 || row.code.indexOf(this.code_input) >=0 ){
          list.push(row)
        }
      }
      this.codeDataList = list
    },
    codeDel(row){
      this.$confirm("你确定要删除通道：" + row.label + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        httpClient("shopCodeDel").post({id:row.id}).then((res)=>{
          console.log(res)
          if(res.code == 0){
            this.codeList(this.row)
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        })
      })

    },
    codeAdd(){
      this.add.shop_id = this.row.id
      httpClient("shopCodeAdd").post(this.add).then((res)=>{
        console.log(res)
        if(res.code == 0){
          this.dialogAdd = false
          this.codeList(this.row)
        }else{
          this.$message({ message: res.msg, type: "error" });
        }
      })
    },
    codeList(row){
      this.row = row
      this.title = row.title+" - 通道码"
      this.dialog = true

      httpClient("shopCodeList").post({shop_id:row.id}).then((res)=>{
        console.log(res)
        if(res.code == 0){
          this.codeDataList = res.data
          this.codeDataListOrigin = res.data
        }
      })
    },
    showAdd(){
      this.add = {
        code:"",
        label:"",
        shop_id:1,
      }
      this.dialogAdd = true
    },
    //获取数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
      ])
        .then((res) => {
          if (res.code == 0) {
            console.log(res);
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 禁用启用
    freezeThaw(row, str) {
      this.row = row;
      this.$confirm("你确定要" + str + "商户：" + row.title + "吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          freezeThaw([{ key: "id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>